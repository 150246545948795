import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "~components";
import Details from "./style";
import { StaticImage as Img } from "gatsby-plugin-image";
import Sidebar from "~sections/Common/Sidebar";
import {
  BlogQoute,
  CommentsBoxSection,
  CommentsFormSection,
} from "./Component";
export default function BlogDetails({title="", richtextData="", first_published_at=""}) {
  return (
    <Details backgroundColor="white">
      <Details.Box pb="0px" pbMD="0px" pbLG="0px">
        <Container>
          <Row className="justify-content-center">
            <Col className="col-lg-12">
              <Details.Box mb="30px" mbLG="55px">
                <Details.Title as="h1">
                  {title}
                </Details.Title>
                <Details.MetaInfo>
                  <Details.Link to="/" as={Link}>
                    {first_published_at}
                  </Details.Link>
                </Details.MetaInfo>
              </Details.Box>
              <Details.Box>
                <Details.Text>
                  {richtextData}
                  </Details.Text>
              </Details.Box>
            </Col>
          </Row>
        </Container>
      </Details.Box>
    </Details>
  );
}
