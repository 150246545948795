import React from "react";
import { PageWrapper } from "~components/Core";
import HeaderButton from "~sections/marketing/Header";
import FooterSix from "~components/Footer";
import HeroImage from "../slices/HeroImageBanner/HeroSection";

import useStoryblok from "../lib/storyblok";
import BlogDetails from "../sections/Blog/BlogDetails";
import { render } from "storyblok-rich-text-react-renderer"
import BlogRegular from "../sections/Blog/BlogRegular";
import SEO from "../components/Seo";

import { graphql ,useStaticQuery } from "gatsby"

const Page = ({ pageContext, location }) => { 

    
  let story = pageContext.story
  story = useStoryblok(story, location);

  const recentPosts = useStaticQuery(graphql`
  {
    stories: allStoryblokEntry(filter: {field_component: {eq: "blog"}}, limit: 3) {
      edges {
        node {
          id
          name
          slug
          field_component
          full_slug
          content
          first_published_at(formatString: "DD MMMM, YYYY")
        }
      }
    }
  }
  
  `)
  
  var recent_stories = []

  for (var post in recentPosts.stories.edges){
    var slug = recentPosts.stories.edges[post]['node']['slug'];
    if (slug == story.slug || recent_stories.length == 3){

    }else{
      recent_stories.push(recentPosts.stories.edges[post])
    }
  }
  

    
  const header = {
    headerClasses: `site-header site-header--menu-start  dark-header site-header--sticky`,
    containerFluid: false,
    darkLogo: true,

    // customLogo: Images.HeaderLogo,
    buttonBlock: (
      <HeaderButton
        className="ms-auto d-none d-xs-inline-flex"
        btnOneText="GET STARTED"
        mr="15px"
        mrLG="0"
      />
    ),
  };

  var custom_blok = {
      height: "50",
      block: [],
      image: story.content.hero,
      alignment: story.content.hero_alignment
  }

  return (
    <PageWrapper headerConfig={header}>
      <SEO title={story.content.seo_title} description={story.content.seo_title} image={story.content.hero.filename} article/>

      <HeroImage blok={custom_blok}/>
      <BlogDetails title={story.content.title} richtextData={render(story.content.copy)} first_published_at={story.first_published_at}/>
      <BlogRegular data={recent_stories}/>

      <FooterSix/>

    </PageWrapper>
  );
}

export default Page
